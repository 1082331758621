.keyboard-item{
    box-sizing: border-box;
    position: relative;
    top: -2px;
    padding: 2px 6px;
    background-color: #fff;
    border: 1px solid #bbb;
    border-radius: 2px;
    background-image: linear-gradient(#fff, #f8f8f8);
    box-shadow: 0 1px 0 rgba(0,0,0,.2);
    color: #666;
    font-size: 12px;
    cursor: default;
    &:hover{
      border-color: #999;
      box-shadow: 0 1px 0 rgba(0,0,0,.4);
    }
  }


  
  .math-item{
    /* box-sizing: border-box; */
    /* position: relative; */
    /* top: -2px; */
    /* padding: 2px 6px; */
    background-color: #649;
    /* border: 1px solid #bbb; */
    /* border-radius: 2px; */
    background-image: linear-gradient(rgb(207, 192, 231), rgb(180, 139, 235));
    box-shadow: 0 1px 0 rgba(0,0,0,.2);
    color: #666;
    /* font-size: 12px; */
    cursor: default;
    &:hover{
      border-color: #999;
      box-shadow: 0 1px 0 rgba(0,0,0,.4);
    }
  }

  .math-edit{
    /* box-sizing: border-box; */
    /* position: relative; */
    /* top: -2px; */
    /* padding: 2px 6px; */
    background-color: rgb(164, 248, 213);
    /* border: 1px solid #bbb; */
    /* border-radius: 2px; */
    /* background-image: linear-gradient(rgb(207, 192, 231), rgb(180, 139, 235)); */
    box-shadow: 0 1px 0 rgba(0,0,0,.2);
    color: rgb(10, 12, 12);
    /* font-size: 12px; */
    cursor: default;
    &:hover{
      border-color: #999;
      box-shadow: 0 1px 0 rgba(0,0,0,.4);
    }
  }


  .foo-block-wrapper{
    display: block;
    height: 50px;
    padding-bottom: 10px;
    overflow: auto;
    font-size: 16px;
    background-color: #08a9fe;
  }

  .foo-block-element{
    position: relative;
    box-sizing: border-box;
    box-shadow: 0 5px 20px rgba(8,169,254,.1);
    text-align: center;
    font-family: 'Hoefler Text', Georgia, serif;
font-size: 30px;

  }