

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.login-form {
  max-width: 300px;
  margin: auto;
}

.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}
.add-form-button {
  width: 40%;
  position: relative;
  float: right;
  margin-top: 40px;
}
.invisible-button {
  visibility: hidden;
  height: 0;
  margin: 0;
}

.survey-form-button {
  width: 30%;
  right: -200px;
}
.social {
  font-size:30px;
  border: 0;
  }


.goal-table-title {
  display: block;
  padding: 2px 4px 2px 4px;
  min-height: 25px;
  /* seems padding and height is related. adding each 1px in padding, add 2px in height */
  /* transition:  box-shadow .5s, transform .5s; */
  position: relative;   /* needed so its absolute-positioned child has a positioned parent */
  /* cursor: url('http://gtms02.alicdn.com/tps/i2/T1_PMSFLBaXXcu5FDa-20-20.png') 10 10,pointer!important; */
  font-size: 12px;
  font-weight: 500;
  /* border-bottom: 1px solid transparent; */
}

.goal-table-title:hover {
  /* font-size: 12px;
  font-weight: 600; */
  /* border-bottom: 1px solid  #c0c0c0; */
  cursor: pointer;
  transform: scale(1.02, 1.02);
  /* text-shadow: 2px 2px 8px #c0c0c0; */
  color: #177ddc;
  transition:  transform .1s;
  /* box-shadow: 2px 2px 5px #c0c0c0; */
}



.answer-in-modal {
  display: block;
  width: 100%;
  background:rgba(131, 123, 123, 0.6);
  border-radius: 4px;
  /* border-width: 1px;
  border-style: solid;
  border-color: rgba(131, 123, 123, 0.3); */
  /* margin: 10px auto 10px auto; */
  /* margin: 10px 10px 10px 10px;   top right bottom left */
  padding: 2px 4px 2px 8px;
  min-height: 25px;
  /* seems padding and height is related. adding each 1px in padding, add 2px in height */
  /* transition:  box-shadow .5s, transform .5s; */
  position: relative;   /* needed so its absolute-positioned child has a positioned parent */
  /* cursor: url('http://gtms02.alicdn.com/tps/i2/T1_PMSFLBaXXcu5FDa-20-20.png') 10 10,pointer!important; */
  font-size: 12px;
  font-weight: 500;
  /* border-bottom: 1px solid transparent; */
}


.answer-in-modal:hover {
  /* font-size: 12px;
  font-weight: 600; */
  /* border-bottom: 1px solid  #c0c0c0; */
  cursor: pointer;
  transform: translate(-2px, -2px);
  transition:  box-shadow .1s, transform .1s;
  box-shadow: 2px 2px 5px #c0c0c0;
}

.answer-in-modal-tag {
  /* display: block; */
  /* width: 100%; */
  background:rgba(131, 123, 123, 0.6);
  border-radius: 4px;
  padding: 2px 4px 2px 4px;
  height: 25px;
  position: relative;   /* needed so its absolute-positioned child has a positioned parent */
  font-size: 12px;
  font-weight: 500;
}

.answer-in-modal-tag:hover {
  cursor: pointer;
  transform: translate(-2px, -2px);
  transition:  box-shadow .1s, transform .1s;
  box-shadow: 2px 2px 5px #c0c0c0;
}



  .list-sort-demo-wrapper {
    position: relative;
    /* background: #e6e6e6; */
    /* overflow: hidden; */
    /* height: 385px; */
    height: auto;
    border-radius: 4px;
    width: 1200px;
  }
  
  .list-sort-demo {
    margin: auto;
    width: 100%;
    position: relative;
    height: auto;
  }

  .list-sort-demo > div {
    overflow: hidden;
  }

 /* .list-sort-demo > div.class-while-dragging{
    overflow: hidden;
  }


  .list-sort-demo > div.class-before-dragging{
    overflow: visible;
  }  */
  
  .list-sort-demo-list {
    /* background: #fff; */
    width: 100%;
    background: rgb(131, 123, 123);
    border-radius: 6px;
    /* margin: 10px auto; */
    margin: 10px auto 10px auto;   /* top right bottom left */
    /* padding: 5px; */
    height: 30px;
    /* seems padding and height is related. adding each 1px in padding, add 2px in height */
    /* transition:  box-shadow .5s, transform .5s; */
    position: relative;   /* needed so its absolute-positioned child has a positioned parent */
    cursor: url('http://gtms02.alicdn.com/tps/i2/T1_PMSFLBaXXcu5FDa-20-20.png') 10 10,pointer!important;
    padding-left: 2px;
    padding-right: 2px;
  }

  .list-sort-demo-list-editing {
    /* background: #fff; */
    width: 100%;
    background: rgb(131, 123, 123);
    border-radius: 6px;
    /* margin: 10px auto; */
    margin: 10px auto 10px auto;   /* top right bottom left */
    /* padding: 5px; */
    height: 30px;
    /* seems padding and height is related. adding each 1px in padding, add 2px in height */
    /* transition:  box-shadow .5s, transform .5s; */
    position: relative;   /* needed so its absolute-positioned child has a positioned parent */
    cursor: url('http://gtms02.alicdn.com/tps/i2/T1_PMSFLBaXXcu5FDa-20-20.png') 10 10,pointer!important;
    padding-left: 2px;
    padding-right: 2px;
  }

  div.list-sort-demo-list:hover {
    /* transform: translate(-2px, -2px); */
    /* transform: scale(1.02, 1.02); */
    background: rgb(151, 146, 146);
    transition:  box-shadow .1s;
    box-shadow: 0px 2px 5px #c0c0c0;
  }

  .list-sort-demo-list-selected {
    background: rgb(111, 103, 103);
    border-radius: 6px;
    margin: 10px auto 10px auto;   /* top right bottom left */
    /* padding: 5px; */
    height: 30px;
    /* seems padding and height is related. adding each 1px in padding, add 2px in height */
    transition:  box-shadow .5s, transform .5s;
    position: relative;
  } 


      /* box-shadow: 1px 2px 8px rgb(151, 143, 143);
    transform: scale(1.1) !important; */

  

  .list-sort-demo-list-inner {
    background: rgb(131, 123, 123);
    border-radius: 6px;
  }
  

  



  div.list-sort-demo-list.list-drag-selected{
    box-shadow: 0 8px 20px #E6E6E6;
    transform: scale(1.02) !important;
  }


  .list-sort-demo-icon {
    width: 10%;
    font-size: 20px;
    vertical-align: middle;
    top: 50%;

  }

  .list-sort-demo-icon-interactive {
    width: 10%;
    font-size: 16px;
    vertical-align: middle;
    top: 50%;

  }

  .list-sort-demo-icon-interactive:hover {
    cursor: pointer !important;
    opacity: 0.6;

  }
  
  /* .list-sort-demo-text{
    width: 80%;
    vertical-align: middle;
    display: inline-block;
  } */

  .list-sort-demo-title {
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    border-bottom: 1px solid transparent;
  }

  .list-sort-demo-title:hover {
    border-bottom: 1px solid  #d0d0d0;
    cursor: pointer;
    transform: translate(-2px, -2px);
    transition:  border-bottom .1s, transform .1s;
    /* box-shadow: 2px 2px 5px #c0c0c0; */
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 16px;
  }
  
  .list-sort-demo-text h1 {
    width:fit-content;
    width:-webkit-fit-content; 
    width:-moz-fit-content;
    font-size: 14px;
    margin-left: 6px;
    border-bottom: 1px solid transparent;
    /* border-radius: 4px; */
  }
  
  .list-sort-demo-text h1:hover {
    width:fit-content;
    width:-webkit-fit-content; 
    width:-moz-fit-content;
    font-size: 14px;
    margin-left: 6px;
    border-bottom: 1px solid  #d0d0d0;
    /* text-decoration: underline; */
    /* border-radius: 4px; */
    cursor: text;
  }




  span.list-sort-input > input[type="text"] {
    border: none;
    outline: none;
    font-size: 14px; 
    font-weight: 500;
  }



  .list-sort-demo-text p{
    font-size: 10px;

  }


  
  /* Custom Coloring */
  
  .ant-carousel .slick-dots li button {
    background: #ff4ef6 !important;
    opacity: 0.4;
    height: 16px !important;
    margin-top: 20px;
    border-radius: 2px !important;

    /* width: 30px;

 */
  }
  
  .ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: #ff4ef6 !important;
    height: 16px !important;
    border-radius: 2px;


    /* width: 30px;

    margin-left: 2px;
    margin-right: 2px; */
  }


  .ant-carousel .slick-dots li {
    width: 24px !important;
    margin-left: 6px !important;
    margin-right: 6px !important;

  }

  .ant-carousel .slick-dots li.slick-active {
    width: 48px !important;
    margin-left: 6px !important;
    margin-right: 6px !important;
  }


  /* ul.ant-skeleton-paragraph li {
    height: 30px !important;
    border-radius: 6px;
  } */


.survey-radio>span {
  font-size: 16px;
}

div.ant-progress-inner {
  /* width: 300px !important;
  height: 300px !important; */
  font-size: 24px !important;
}

span.ant-progress-text {
  width: 300px !important;
}


.parentBox {
    display: flex; /* or inline-flex */
    flex-direction: row;
}

.rightSlider {
  /* display: inline-block; */
  /* height: 300; */
  /* margin-left: 70; */
  width: 5;
  flex: 2;
}

.leftList {
  flex-grow: 4;
  flex: 1;

width: 500;
}


.parent {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ant-slider-disabled {
  cursor: default;
  pointer-events: none;
}

/* .ant-checkbox-wrapper-disabled .ant-checkbox-disabled{
  cursor: default;
  pointer-events: none;
} */


.inputCharCounterBlock {
  position: relative;
}

.inputCharCounter {
  position: absolute;
  bottom: 3px;
  right: 8px;
  z-index: 2;
}

/* .inputCharCounterOver {
  position: absolute;
  bottom: 5px;
  right: 15px;
  z-index: 2;
  color: #FFBF00;
} */


.ant-select-item-option-selected {
  display: none !important;
}


.ant-input-number-handler-wrap { 
  visibility: hidden;
}


.limitTextArea-input {
  padding-right: 47px !important;
}

.post-browse-tree div.ant-tree-treenode{
  margin-bottom: 2px;

}

.post-browse-tree div.ant-tree-treenode span.ant-tree-title {
  font-size: 14px;

}

/* div.ant-tree-treenode span.ant-tree-title */


.titleEdit input {
  font-size: 28px;
  width: 100%;
}


.postTitle {
  color: #ff83c1;
  font-size: 32px;
}


.postTitle:hover {
  /* font-size: 12px;
  font-weight: 600; */
  /* border-bottom: 1px solid  #c0c0c0; */
  cursor: pointer;
  /* transform: translate(1px, 1px); */
  transform: scale(1.02);
  transition:  transform .5s;
  color:#FF69B4;
  /* box-shadow: 2px 2px 5px #c0c0c0; */
}



footer.ant-layout-footer {
  background-color: #1f1f1f !important;
  font-size: 14px;
  /* height: 30px; */
}



